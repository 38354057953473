
import { Options, Prop, Vue } from 'vue-property-decorator'
import Button from 'primevue/button'
import * as fileSaver from 'file-saver'
import { query } from '@madxnl/mrrabbit'
import axios from 'axios'

@Options({
  components: {
    Button,
  },
})
export default class ReceivedPacketMetricsDownloader extends Vue {
  @Prop()
  modelId: string

  loading = false

  get apiKey() {
    return localStorage.getItem('apiKey')
  }

  async createFile() {
    this.loading = true
    const result = await query(
      'exportRecentReceivedPacketsMetrics',
      { variables: { exportRecentReceivedPacketsMetrics: { bagId: this.modelId } } },
    )

    if (result.exportRecentReceivedPacketsMetrics != null) {
      this.loading = false
      await this.download(result.exportRecentReceivedPacketsMetrics)
    }
  }

  async download(ref) {
    const { data } = await axios.get(ref, {
      headers: {
        'X-Authorization-Method': 'api',
        Authorization: 'basic ' + this.apiKey,
      },
      responseType: 'blob',
    })
    fileSaver.saveAs(data, 'received_packets.csv')
  }
}
