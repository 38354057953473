
import { Prop, Vue } from 'vue-property-decorator'

export default class StabilityIndicator extends Vue {
  @Prop()
  value: number

  get circleClasses() {
    let classes = 'nr-l-circle '
    if (this.value === 0) {
      classes += 'nr-l-green'
    } else if (this.value === 1) {
      classes += 'nr-l-yellow'
    } else {
      classes += 'nr-l-red'
    }
    return classes
  }

  get tooltip() {
    const plurality = this.value === 1 ? '' : 's'
    return `${this.value} reboot${plurality} in the past hour.`
  }
}
