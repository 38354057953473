import { madhatterGraphqlClient } from '@madxnl/mrrabbit'

export async function fetchBagLogs(bagId: string) {
  const data = await madhatterGraphqlClient.query({
    operationName: 'logByBagId',
    fields: { log: true },
    variables: {
      logByBagId: { input: bagId },
    },
  })
  return data.logByBagId
}
