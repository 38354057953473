import { createRouter, createWebHistory } from 'vue-router'
import { config } from '../../nextround'
import { routesFactory } from '@madxnl/mrrabbit'

const routes = routesFactory(config)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
