import { createApp } from 'vue'
import router from './router/index'
import PrimeVue from 'primevue/config'
import { App, init } from '@madxnl/mrrabbit'
import { FormattingUtils } from './utils'

import ToastService from 'primevue/toastservice'
import { config } from '../nextround'
import ConfirmationService from 'primevue/confirmationservice'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

// Force deploy comment

// Make our components available anywhere
const app = createApp(App, {
  config,
})

const utils = new FormattingUtils()

const requireComponent = require.context('./', true, /[A-Z]\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const match = fileName.match(/(\w+)\.\w+$/)
  if (match) app.component(match[1], requireComponent(fileName).default)
})
init(app)
app.use(router)
app.use(ToastService)
app.use(ConfirmationService)
app.use(PrimeVue)
app.mount('#app')
