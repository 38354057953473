
import { Options, Prop, Vue } from 'vue-property-decorator'
import { fetch, mutation } from '@madxnl/mrrabbit'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { debounce } from 'lodash'

@Options({
  components: {
    Button,
    Dropdown,
  },
})
export default class BagFirmwareSelector extends Vue {
  @Prop()
  modelId: string

  firmwareOptions: {name: string, id: string}[] = []

  selectedFirmware: string = null

  DEBOUNCE_TIMEOUT = 500
  debouncedFn = debounce((args) => this.fetchData(args), this.DEBOUNCE_TIMEOUT)

  created() {
    this.fetchData()
  }

  async fetchData(input = null) {
    const result = await fetch(
      'Firmware',
      {
        id: true,
        name: true,
      },
      input,
      { size: 1000 },
    )
    this.firmwareOptions = result.data
  }

  async onFilter({ value }) {
    this.debouncedFn({ name: value })
  }

  upload() {
    mutation('startOta', { variables: { startOta: { input: { bagId: this.modelId, firmwareId: this.selectedFirmware } } }, returnFields: null })
  }
}

