import { Formatting } from '@madxnl/mrrabbit'

export class FormattingUtils {
  @Formatting('decimal')
  formatDecimals(value: number): string {
    if (value == null) {
      return '-'
    }
    return value.toFixed(2)
  }
}
