
import { Prop, Vue } from 'vue-property-decorator'
import { fetchBagLogs } from '../graphql'

export default class Log extends Vue {
  @Prop()
  value: Record<string, unknown>

  lines = []

  isUnmounted = false

  created() {
    this.fetchData()
  }

  unmounted() {
    this.isUnmounted = true
  }

  async fetchData() {
    const data = await fetchBagLogs(this.value.id as string)
    this.lines = data
    if (!this.isUnmounted) {
      setTimeout(() => this.fetchData(), 500)
    }
  }
}
